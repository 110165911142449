import {
  WorkOrderType,
  WorkOrderStatus,
  WorkOrderMilestoneName,
  getCountryAbbreviationFromWorkOrderType,
  EuCountryCodes,
} from '@xbcb/work-order-types';
import { AppRecordSubmitButton } from 'routes';
import { constantCase, pascalCase } from 'change-case';
import { RecordType } from '@xbcb/shared-types';
import { AppRecordOnConfirmPayload } from '../../types';
import { executeMutation } from 'libs/executeMutation';
import { safeGetMessage, setModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import {
  createWorkOrderMilestoneMutation,
  updateEuCustomsEntry,
  updateUsConsumptionEntry,
} from 'libs/sharedQueries';
import { getRecordType } from '@xbcb/core';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { WorkOrderBadge } from '@xbcb/work-order-types/dist/enums/workOrder/badge';
import { v4 } from 'uuid';
import { isInternalForwarder } from '@xbcb/party-utils';
import { isRecordCanceled, isRecordDeleted } from 'libs/workOrderConditions';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { isEuCustomsEntry } from '@xbcb/work-order-utils/dist/isEuCustomsEntry';
import { getLocalizedRecordName } from 'libs/localizationHelpers';
import { isEuCustomsDeclaration } from '@xbcb/work-order-utils/dist/isEuCustomsDeclaration';
import { updateCustomsDeclaration } from '../workOrderRoutes/customsDeclaration/customsDeclaration.query';

const getWorkOrder = (shipment: any, workOrderType: RecordType) => {
  const filteredWorkOrders = shipment?.workOrderGroup?.workOrders?.filter(
    (workOrder: { id: string }) =>
      getRecordType(workOrder.id) === workOrderType,
  );
  if (filteredWorkOrders && filteredWorkOrders.length) {
    return filteredWorkOrders[0];
  }
  return null;
};

export const markWorkOrderHotButtons = (): AppRecordSubmitButton[] => {
  const buttons: AppRecordSubmitButton[] = [];
  Object.keys(WorkOrderType).forEach((workOrderType) => {
    buttons.push(markWorkOrderHotButton(workOrderType as WorkOrderType));
  });
  return buttons;
};

export const markWorkOrderHotButton = (
  workOrderType: WorkOrderType,
): AppRecordSubmitButton => {
  const recordType = constantCase(workOrderType) as RecordType;
  const workOrderButton: AppRecordSubmitButton = {
    key: `mark${recordType}Hot`,
    text: ({ currentUser, appRecordBundle, recordNameBundle }) => {
      const recordName = getLocalizedRecordName(
        recordNameBundle,
        recordType,
        currentUser.accountType,
      );
      return safeGetMessage(appRecordBundle, 'mark_record_hot', { recordName });
    },
    show: ({ existingRecord, currentUser, workOrderTaskType }) => {
      let workOrder = null;
      let forwarder = null;
      if (getRecordType(existingRecord?.id) === RecordType.SHIPMENT) {
        workOrder = getWorkOrder(existingRecord, recordType);
        forwarder = existingRecord?.forwarders?.[0]?.forwarder;
      } else {
        workOrder = existingRecord;
        forwarder = workOrder?.group?.forwarders?.[0]?.forwarder;
      }

      // Today only usConsumptionEntry and euCustomsEntry supports mark hot feature
      const supportHotWorkOrder =
        workOrder &&
        (isEuCustomsEntry(workOrder?.id) ||
          isEuCustomsDeclaration(workOrder) ||
          (!isInternalForwarder(forwarder) &&
            getRecordType(workOrder?.id) === RecordType.US_CONSUMPTION_ENTRY));
      return (
        workOrder &&
        workOrder.status &&
        ![
          WorkOrderStatus.NOT_STARTED,
          WorkOrderStatus.COMPLETED,
          WorkOrderStatus.REPLACED,
        ].includes(workOrder.status) &&
        !isRecordCanceled(workOrder) &&
        supportHotWorkOrder &&
        (!workOrder.badges ||
          !workOrder.badges?.includes(WorkOrderBadge.HOT)) &&
        !workOrderTaskType &&
        !isRecordDeleted(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !currentUser.loading &&
        checkAccess(currentUser, recordType, UserPermissions.UPDATE)
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onConfirm: ({ dispatch, existingRecord }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve) => {
        let workOrders = [];
        if (getRecordType(existingRecord?.id) === RecordType.SHIPMENT) {
          workOrders = existingRecord?.workOrderGroup?.workOrders;
        } else {
          workOrders = [existingRecord];
        }
        dispatch(
          setModal({
            key: ModalKey.MARK_WORK_ORDER_HOT,
            props: {
              visible: true,
              sendConfirmationDecision: resolve,
              workOrders: workOrders,
              workOrderType: recordType,
            },
          }),
        );
      }),
    onSubmit: async ({ updatedRecord }) => {
      let workOrder = null;
      if (getRecordType(updatedRecord?.id) === RecordType.SHIPMENT) {
        workOrder = getWorkOrder(updatedRecord, recordType);
      } else {
        workOrder = updatedRecord;
      }
      const isEuEntry =
        isEuCustomsEntry(workOrder.id) || isEuCustomsDeclaration(workOrder);
      if (workOrderType === WorkOrderType.UsConsumptionEntry || isEuEntry) {
        await executeMutation({
          mutation: createWorkOrderMilestoneMutation,
          successMessage: `Hot Work Order Requested milestone published!`,
          variables: {
            idempotencyKey: v4(),
            input: {
              allowDuplicate: false,
              name: WorkOrderMilestoneName.HOT_WORK_ORDER_REQUESTED,
              workOrder: {
                id: workOrder.id,
                version: workOrder.version,
              },
            },
          },
        });
        // The WO update needs to happen after the milestone is created so that
        // the hot work order request milestone is there when we are recalculating
        // the new deadline
        await fetchLatestRecordAndExecuteMutation({
          id: workOrder.id,
          recordType: pascalCase(workOrderType) as RecordType,
          mutation: isEuEntry
            ? isEuCustomsDeclaration(workOrder)
              ? updateCustomsDeclaration
              : updateEuCustomsEntry(
                  getCountryAbbreviationFromWorkOrderType(
                    pascalCase(recordType) as WorkOrderType,
                  ) as EuCountryCodes,
                )
            : updateUsConsumptionEntry,
          constructVariablesWithIdVersion: (id, version) => ({
            id: id,
            version: version,
            input: {
              badges: [WorkOrderBadge.HOT],
            },
          }),
        });
      }
    },
  };
  return workOrderButton;
};
