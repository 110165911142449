import { v4 } from 'uuid';
import {
  WorkOrderType,
  WorkOrderMilestoneName,
  WorkOrderStatus,
  getCountryAbbreviationFromWorkOrderType,
  EuCountryCodes,
  WorkOrderBadge,
} from '@xbcb/work-order-types';
import { safeGetMessage, setModal } from '@xbcb/ui-utils';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { getRecordType } from '@xbcb/core';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { AppRecordSubmitButton } from 'routes';
import { constantCase, pascalCase } from 'change-case';
import { BillingPartyAccountStatus, RecordType } from '@xbcb/shared-types';
import type { WorkOrder } from '@xbcb/api-gateway-client';
import { AppRecordOnConfirmPayload } from '../../types';
import { executeMutation } from 'libs/executeMutation';
import { ModalKey } from 'types';
import { getPayer } from 'libs/payer';
import {
  createWorkOrderMilestoneMutation,
  updateUsConsumptionEntry,
  updateEuCustomsEntry,
} from 'libs/sharedQueries';
import { isRecordCanceled, isRecordDeleted } from 'libs/workOrderConditions';
import { isEuCustomsEntry } from '@xbcb/work-order-utils/dist/isEuCustomsEntry';
import { isEuCustomsDeclaration } from '@xbcb/work-order-utils/dist/isEuCustomsDeclaration';
import { fetchLatestRecord } from '@xbcb/apollo-client';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

const getWorkOrder = (shipment: any, workOrderType: RecordType) => {
  const filteredWorkOrders = shipment?.workOrderGroup?.workOrders?.filter(
    (workOrder: { id: string }) =>
      getRecordType(workOrder.id) === workOrderType,
  );
  if (filteredWorkOrders && filteredWorkOrders.length) {
    return filteredWorkOrders[0];
  }
  return null;
};

export const markWorkOrderReadyButtons = (): AppRecordSubmitButton[] => {
  const buttons: AppRecordSubmitButton[] = [];
  Object.keys(WorkOrderType).forEach((workOrderType) => {
    buttons.push(markWorkOrderButton(workOrderType as WorkOrderType));
  });
  return buttons;
};

export const markWorkOrderButton = (
  workOrderType: WorkOrderType,
): AppRecordSubmitButton => {
  const recordType = constantCase(workOrderType) as RecordType;
  const workOrderButton: AppRecordSubmitButton = {
    key: `mark${recordType}Ready`,
    text: ({ currentUser, appRecordBundle, recordNameBundle }) => {
      const recordName = getLocalizedRecordName(
        recordNameBundle,
        recordType,
        currentUser.accountType,
      );
      return safeGetMessage(appRecordBundle, 'mark_record_ready', {
        recordName,
      });
    },
    show: ({ existingRecord, currentUser }) => {
      let workOrder = null;
      if (getRecordType(existingRecord?.id) === RecordType.SHIPMENT) {
        workOrder = getWorkOrder(existingRecord, recordType);
      } else {
        workOrder = existingRecord;
      }
      return (
        workOrder &&
        workOrder.status === WorkOrderStatus.NOT_STARTED &&
        !isRecordDeleted(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !currentUser.loading &&
        checkAccess(currentUser, recordType, UserPermissions.UPDATE)
      );
    },
    disableTooltipText: 'This account has been suspended',
    disable: ({ existingRecord }) => {
      let workOrderGroup = null;
      if (getRecordType(existingRecord?.id) === RecordType.SHIPMENT) {
        workOrderGroup = existingRecord?.workOrderGroup;
      } else {
        workOrderGroup = existingRecord?.group;
      }
      const payer = getPayer(workOrderGroup, workOrderType);
      const buttonDisabled =
        BillingPartyAccountStatus.SUSPENDED === payer?.billingDetails?.status;
      return buttonDisabled;
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onConfirm: ({ dispatch, existingRecord }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve) => {
        let workOrders = [];
        if (getRecordType(existingRecord?.id) === RecordType.SHIPMENT) {
          workOrders = existingRecord?.workOrderGroup?.workOrders;
        } else {
          workOrders = [existingRecord];
        }
        dispatch(
          setModal({
            key: ModalKey.MARK_WORK_ORDER_READY,
            props: {
              visible: true,
              sendConfirmationDecision: resolve,
              workOrders: workOrders,
              workOrderType: recordType,
            },
          }),
        );
      }),
    onSubmit: async ({ updatedRecord, confirmationData }) => {
      let workOrder: WorkOrder;
      if (getRecordType(updatedRecord?.id) === RecordType.SHIPMENT) {
        workOrder = getWorkOrder(updatedRecord, recordType);
      } else {
        workOrder = updatedRecord;
      }
      const isHotUsConsumptionEntry =
        confirmationData?.isHot &&
        workOrderType === WorkOrderType.UsConsumptionEntry;
      const isHotEuCustomsEntry =
        confirmationData?.isHot &&
        (isEuCustomsEntry(workOrder.id) || isEuCustomsDeclaration(workOrder));
      // Publish WORK_OREDER_READY milestone
      await fetchLatestRecordAndExecuteMutation({
        id: workOrder.id,
        recordType: pascalCase(workOrderType) as RecordType,
        successMessage: `Work Order Ready milestone published!`,
        mutation: createWorkOrderMilestoneMutation,
        constructVariablesWithIdVersion: (id, version) => ({
          idempotencyKey: v4(),
          input: {
            allowDuplicate: false,
            name: WorkOrderMilestoneName.WORK_ORDER_READY,
            workOrder: {
              id,
              version,
            },
          },
        }),
      });

      if (isHotUsConsumptionEntry || isHotEuCustomsEntry) {
        // Publish the HOT WO request milestone
        await fetchLatestRecordAndExecuteMutation({
          id: workOrder.id,
          recordType: pascalCase(workOrderType) as RecordType,
          mutation: createWorkOrderMilestoneMutation,
          successMessage: `Hot Work Order Requested milestone published!`,
          constructVariablesWithIdVersion: (id, version) => ({
            idempotencyKey: v4(),
            input: {
              allowDuplicate: false,
              name: WorkOrderMilestoneName.HOT_WORK_ORDER_REQUESTED,
              workOrder: {
                id,
                version,
              },
            },
          }),
        });

        const latestRecord = await fetchLatestRecord({
          id: workOrder.id,
          recordType,
          fields: '',
        });
        await executeMutation({
          mutation: isHotEuCustomsEntry
            ? updateEuCustomsEntry(
                getCountryAbbreviationFromWorkOrderType(
                  pascalCase(recordType) as WorkOrderType,
                ) as EuCountryCodes,
              )
            : updateUsConsumptionEntry,
          variables: {
            id: workOrder.id,
            version: latestRecord.version,
            input: {
              badges: [WorkOrderBadge.HOT],
            },
          },
        });
      }
    },
  };
  return workOrderButton;
};
